hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #bbbab9;
  margin: 1em 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #0867fd;
}

.AppContent {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 18cm;
  padding-left: 4vw;
  padding-right: 4vw;
}

.Footer {
  margin-left: 4vw;
  padding-right: 4vw;
}

.FooterContent {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 18cm;
  padding-left: 4vw;
  padding-right: 4vw;
  padding-bottom: 1em;
}
