.Header {
  margin-top: 4em;
  margin-bottom: 4em;
  padding: 2em;
  text-align: center;
  background-color: #faf8f6;
  border-top: 1px solid #bbbab9;
  border-bottom: 1px solid #bbbab9;
}

.Header>h1 {
  font-size: xxx-large;
  font-weight: normal;
  margin: 0;
}

.Header>h2 {
  font-size: normal;
  font-weight: normal;
}

nav {
  font-size: normal;
  font-weight: normal;
  margin: 1.5vw;
}

nav>a {
  font-size: normal;
  font-weight: normal;
  margin: 1.25vw;
}
